<template>
  <div class="topbar">
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <b-button class="btn btn-user btn-md btn-hover-transparent-white btn-dropdown">
          <span class="text-white opacity-70 font-weight-bold d-md-inline mr-1">Salut, </span>
          <span class="text-white opacity-90 font-weight-bolder d-md-inline mr-4">{{ currentUser.name }}</span>

          <span class="symbol symbol-35">
            <span class="symbol-label bg-white-o-30">
              <span class="svg-icon svg-icon-xl">
                <inline-svg src="media/svg/icons/General/User.svg" />
              </span>
            </span>
          </span>
        </b-button>
      </template>

      <b-dropdown-text tag="div" class="min-w-md-350px">
        <UserDropdown />
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import UserDropdown from "@/view/layout/header/dropdown/UserDropdown.vue";

export default {
  name: "KTTopbar",

  components: {
    UserDropdown,
  },

  computed: {
    ...mapGetters([ "currentUser", "isLoggedIn" ]),
  },
};
</script>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }

  .btn-user {
    .symbol {
      .svg-icon {
        margin-right: 0;
      }
    }
  }
}
</style>
