<template>
  <div class="page-loader page-loader-logo">
    <img alt="Logo" width="100" v-bind:src="logo" />
    <div class="spinner" v-bind:class="spinnerClass" />
  </div>
</template>

<script>
export default {
  name: "Loader",

  props: {
    logo: {
      type: String,
      required: true,
    },

    spinnerClass: {
      type: String,
      default: "spinner-primary",
    },
  },
};
</script>
