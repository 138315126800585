<template>
  <div class="user-dropdown">
    <div class="user-dropdown-header d-flex flex-column flex-center rounded-top">
      <h4 class="user-dropdown-title">
        Setări utilizator
      </h4>
    </div>

    <div class="user-dropdown-body row row-paddingless">
      <template v-for="(item, i) in list">
        <div class="col-6" v-bind:key="i">
          <div
            class="user-dropdown-option btn btn-clean border-right border-bottom"
            v-bind:class="{ disabled: item.disabled, 'bg-hover-light': !item.disabled }"
            v-on:click="item.action"
          >
            <span class="svg-icon svg-icon-3x" v-bind:class="{ 'svg-icon-success': !item.disabled }">
              <inline-svg v-bind:src="item.svg" />
            </span>

            <div class="user-dropdown-option-title">
              {{ item.title }}
            </div>

            <div v-if="item.desc" class="user-dropdown-option-desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDropdown",

  data() {
    return {
      list: [
        {
          title: "Setări",
          svg: "/media/svg/icons/Shopping/Settings.svg",
          action: this.settings,
          disabled: true,
        },
        {
          title: "Delogare",
          svg: "/media/svg/icons/Navigation/Sign-out.svg",
          action: this.logout,
        }
      ],
    };
  },

  methods: {
    async logout() {
      await this.$store.dispatch("logout");

        this.$router.push({ name: "login" });
    },

    settings() {

    },
  },
};
</script>

<style lang="scss">
.user-dropdown {
  .user-dropdown-header {
    background-image: url('/media/misc/bg-1.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    padding-top: 2.5rem;
    padding-bottom: 2.25rem;

    .user-dropdown-title {
      color: white;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .user-dropdown-body {
    .user-dropdown-option {
      display: block;
      padding: 2.5rem 1.5rem;
      text-align: center;

      &.disabled {
        cursor: initial;
      }

      .user-dropdown-option-title {
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;

        color: $dark-75;
        font-weight: 500;
        font-size: $h6-font-size;
      }

      .user-dropdown-option-desc {
        color: $dark-50;
        font-size: $font-size-lg;
      }
    }
  }
}
</style>
